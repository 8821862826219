import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`:`}</p>
    <p>{`The last of the students left ITC in Delft and the courses in Exploration Geophysics and Mineral Exploration were relocated to the Enschede campus on 1 September 2000.  Six months later, on 1 March 2001, Colin became part-time at ITC and set up Earthworks as a consultancy. Since consulting missions were always an active part of Colin’s work within the institute itself, it was not possible to pursue consulting work independently before this time. This was the first move towards full retirement from the institute in December 2004. By that time, Colin had already set up his office in a shared office building on Lange Geer in Delft and he is still there. Twenty years later he has seen the completion of projects in many parts of the southern hemisphere, as laid out under ‘About Earthworks’.`}</p>
    <p>{`We mark the occasion with the launch of this completely redesigned website.  After ten years of service, the technology behind the old website became obsolete.  We apologise to those who have been unable to follow some of the links there since late in 2020. The new website is designed to allow access to the Gondwana reconstruction work that Colin has concentrated on exclusively since finishing the last of the airborne geophysical survey assignments in Africa in 2018.  `}</p>
    <p>{`Particularly during the past 12 months of coronavirus lockdown great strides have been made in understanding elements of the process of Gondwana disruption through studying sea-floor topography and building and re-building iteratively the geometrical model, particularly of the defining events of Early Cretaceous times.  You will, I hope, find lots of material here to help you access and understand these results.  As always, I will be pleased to hear from anyone with questions or feedback.`}</p>
    <p>{`My thanks go to Gijs Burghoorn and the people at Kojac for their efforts on the new website. `}</p>
    <p><strong parentName="p">{`Colin Reeves`}</strong></p>
    <p><em parentName="p">{`2021 April 13`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      